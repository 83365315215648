<template>
    <div class="mypage-modal-wrap">
        <div class="top_wrap">
            <div class="modal_title">{{ $t('r_nick') }} {{ $t('enroll') }}</div>
            <div class="close_btn_wrap">
                <button class="close_modal" @click="modalClose()"></button>
            </div>
        </div>
        <div class="middle_wrap">
            <el-input class="el_info_input" type="text" maxlength="20"
                      :placeholder="$t('r_write_nick')" v-model="referrerNick"
                      @input="parentChange('referrerNick',referrerNick)"
                      @keypress.enter="save()"/>
            <!--       추천인 닉네임 체크 -->
            <div class="validation referrer" v-if="validation.hasError('referrerNick')">
                {{ validation.firstError('referrerNick') }}
            </div>
            <div class="validation referrer" v-else-if="this.nick == this.referrerNick">
              {{ this.$t('referrerErrorMsg1') }}
            </div>
            <div class="validation referrer" v-else-if="referrerNickChk">
                {{ this.$t('referrerErrorMsg') }}
            </div>
            <div class="confirm_btn_wrap">
                <button class="btn save" @click="save()">{{ $t('enroll') }}</button>
                <button class="btn cancel" @click="modalClose()">{{ $t('cancel') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import memberValidator from "@/mixins/validators/memberValidator";

export default {
    name: "MemberWriteReferrerLayout",
    mixins: [alertMixins, memberValidator],
    components: {},
    inject: ['memberSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            checkReferrer: false
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.nick = this.UserInfo.mb_nick
        EventBus.$on('checkData', this.checkData);
    },
    beforeDestroy() {
        EventBus.$off('checkData');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        modalClose() {
            this.referrerNick = '';
            this.referrerNickChk = false;
            this.$modal.hide('mypage-referrer-modal')
        },
        save() {
            if(this.nick == this.referrerNick){
              this.errorAlert(this.$t('referrerErrorMsg1'));
              return;
            }else{
              EventBus.$emit('updateMember')
              return;
            }
        },
        checkData(type) {
            this.referrerNickChk = type;
        },
        parentChange(type, value) {
            this[type] = value;
            this.memberSetData(type, value);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>